import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"

import {Button, Divider, Timeline} from 'antd';
import SearchOutlined from '@ant-design/icons';
import TimelineItem from "antd/es/timeline/TimelineItem";

const Impress = () => (
    <Container defKey="6">
        <SEO title="Impressum"/>
        <div style={{padding: 50}}>
            <article className="uk-article">
                <h3>Impressum</h3>
                <p>Angaben gem&auml;&szlig; &sect; 5 TMG:</p>
                <p>Olaf Laur<br/><br/>Saarstra&szlig;e 19<br/>23747 Dahme</p>
                <p>&nbsp;</p>
                <h2>Kontakt:</h2>
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <p>E-Mail:</p>
                        </td>
                        <td>
                            <p><a href="/cdn-cgi/l/email-protection" className="__cf_email__"
                                  data-cfemail="9ce8f9f5f7f3f6e9e8efe9dcebf9feb2f8f9">[email&#160;protected]</a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>&nbsp;</p>
                <h2>Haftungsausschluss:</h2>
                <p><strong>Haftung f&uuml;r Inhalte</strong></p>
                <p>Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter Sorgfalt erstellt. F&uuml;r die
                    Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte k&ouml;nnen wir jedoch keine
                    Gew&auml;hr &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG
                    f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
                    Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                    verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder
                    nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
                    Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem
                    Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                    entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                <p><strong>Haftung f&uuml;r Links</strong></p>
                <p>Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
                    Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine
                    Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
                    Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt
                    der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige
                    Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                    Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                    nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                    entfernen.</p>
                <p><strong>Urheberrecht</strong></p>
                <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
                    Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen
                    Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur
                    f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
                    Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
                    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                <p><strong>Datenschutz</strong></p>
                <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten m&ouml;glich.
                    Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                    eMail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich, stets auf freiwilliger Basis.
                    Diese Daten werden ohne Ihre ausdr&uuml;ckliche Zustimmung nicht an Dritte weitergegeben. Wir
                    weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B. bei der Kommunikation per
                    E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem
                    Zugriff durch Dritte ist nicht m&ouml;glich.Der Nutzung von im Rahmen der Impressumspflicht
                    ver&ouml;ffentlichten Kontaktdaten durch Dritte zur &Uuml;bersendung von nicht ausdr&uuml;cklich
                    angeforderter Werbung und Informationsmaterialien wird hiermit ausdr&uuml;cklich widersprochen.
                    Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der
                    unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
                <p>Google Analytics</p>
                <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
                    (&bdquo;Google&ldquo;). Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, Textdateien, die
                    auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                    erm&ouml;glichen. Die durch das Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser
                    Website werden in der Regel an einen Server von Google in den USA &uuml;bertragen und dort
                    gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website, wird Ihre
                    IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europ&auml;ischen Union oder in
                    anderen Vertragsstaaten des Abkommens &uuml;ber den Europ&auml;ischen Wirtschaftsraum zuvor
                    gek&uuml;rzt. Nur in Ausnahmef&auml;llen wird die volle IP-Adresse an einen Server von Google in
                    den USA &uuml;bertragen und dort gek&uuml;rzt. Im Auftrag des Betreibers dieser Website wird
                    Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
                    Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der
                    Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegen&uuml;ber dem
                    Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem
                    Browser &uuml;bermittelte IP-Adresse wird nicht mit anderen Daten von Google
                    zusammengef&uuml;hrt. Sie k&ouml;nnen die Speicherung der Cookies durch eine entsprechende
                    Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
                    diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich
                    werden nutzen k&ouml;nnen. Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das
                    Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
                    Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem
                    folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de) verf&uuml;gbare Browser-Plugin
                    herunterladen und installieren. Sie k&ouml;nnen die Erfassung durch Google Analytics verhindern,
                    indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, das die
                    zuk&uuml;nftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert: <a href="#">Google
                        Analytics deaktivieren</a> N&auml;here Informationen zu Nutzungsbedingungen und Datenschutz
                    finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter
                    https://www.google.de/intl/de/policies/. Wir weisen Sie darauf hin, dass auf dieser Website
                    Google Analytics um den Code &bdquo;gat._anonymizeIp();&ldquo; erweitert wurde, um eine
                    anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gew&auml;hrleisten.</p>
            </article>

        </div>
    </Container>
)

export default Impress
